<template>
  <div class="insurance-certificate-skeleton">
    <Skeleton class="mb-3 mt-3" width="20%"></Skeleton>
    <div class="d-flex flex-row justify-content-between">
      <div class="radio-buttons-container">
        <Skeleton class="mb-4 mt-3" width="35%" height="23px"></Skeleton>
        <Skeleton class="mb-3 mt-3" width="30%" height="23px"></Skeleton>
        <Skeleton class="mb-3 mt-3" width="50%" height="23px"></Skeleton>
        <Skeleton class="mb-3 mt-3" width="25%" height="23px"></Skeleton>
        <Skeleton class="mb-3 mt-3" width="50%" height="23px"></Skeleton>
        <Skeleton class="mb-3 mt-3" width="25%" height="23px"></Skeleton>
      </div>
      <div class="radio-buttons-container">
        <Skeleton class="mb-4 mt-3" width="35%" height="23px"></Skeleton>
        <Skeleton class="mb-3 mt-3" width="50%" height="23px"></Skeleton>
        <Skeleton class="mb-3 mt-3" width="25%" height="23px"></Skeleton>
        <Skeleton class="mb-3 mt-3" width="50%" height="23px"></Skeleton>
      </div>
    </div>
    <div class="insurance-certificate-form">
      <Skeleton class="mb-2 mt-2 item" width="95%" height="40px"></Skeleton>
      <Skeleton class="mb-2 mt-2 item" width="95%" height="40px"></Skeleton>
      <Skeleton class="mb-2 mt-2 item" width="95%" height="40px"></Skeleton>
      <Skeleton class="mb-2 mt-2 item" width="95%" height="40px"></Skeleton>
      <Skeleton class="mb-2 mt-2 item" width="95%" height="40px"></Skeleton>
    </div>
    <div class="insurance-aditional-data d-flex flex-row justify-content-between mt-3">
      <Skeleton class="mb-2 mt-2 item" width="31%" height="40px"></Skeleton>
      <Skeleton class="mb-2 mt-2 item" width="31%" height="40px"></Skeleton>
      <Skeleton class="mb-2 mt-2 item" width="31%" height="40px"></Skeleton>
    </div>
    <div class="button-container">
      <Skeleton class="mb-3 mt-3" width="15%" height="40px"></Skeleton>
    </div>
  </div>
</template>

<script>
import Skeleton from "primevue/skeleton";

export default {
  name: "InsuranceCertificateSkeleton",
  components: {
    Skeleton
  }
};
</script>

<style lang="scss" scoped>
.radio-buttons-container {
  width: 50%;
}
.insurance-certificate-form {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
}
.button-container {
  margin: auto;
  display: flex;
  justify-content: center;
}
::v-deep .p-skeleton {
  background-color: #d9d9d9;
}
@media (max-width: 810px) {
  .insurance-certificate-form {
    grid-template-columns: 1fr;
  }
  .insurance-aditional-data {
    flex-direction: column !important;
  }
  .item {
    width: 100% !important;
    margin: auto;
  }
}
</style>
