<template>
  <div class="insurance-acceptance">
    <div class="insurance-acceptance__subtitle">
      <p>
        Please confirm the following information and upload any required documents (if necessary):
      </p>
    </div>
    <ul class="insurance-acceptance-list">
      <li class="insurance-acceptance-list__item">
        <strong>Conveyance</strong>
        <span>{{ selectedInsurance.conveyance }}</span>
      </li>
      <li class="insurance-acceptance-list__item">
        <strong>Commodity</strong>
        <span>{{ selectedInsurance.commodity }}</span>
      </li>
      <li class="insurance-acceptance-list__item">
        <strong>Insured Value</strong>
        <span>$ {{ selectedInsurance.insured_value | insurancePrice }}</span>
      </li>
      <li class="insurance-acceptance-list__item">
        <strong>Selected Insurance</strong>
        <span
          >{{
            selectedInsurance.condition === "Brand New Goods" ? '"All Risk"' : '"TOTAL LOSS"'
          }}
          coverage: {{ selectedInsurance.customer_cost | priceToUsd }}</span
        >
      </li>
      <li class="insurance-acceptance-list__item">
        <strong>Notes</strong>
        <span>This quote is valid for 15 days</span>
      </li>
    </ul>
    <div class="documents-container">
      <strong class="documents-container__text">
        If you want to attach any documents such as Commercial Invoice, transport document, pictures
        or other supporting documents.
      </strong>
      <DragAndDrop
        class="drag-and-drop"
        :files="files"
        :allowedExtensions="['pdf']"
        :maxFiles="6"
        ref="insuranceDocuments"
      >
        <template v-slot:files-list-section>
          <div v-if="files.length > 0" class="document-content mb-3 mt-3">
            <div class="document-content-list" v-for="(file, index) in files" :key="index">
              <i class="pi pi-file-pdf"></i>
              <p>{{ file.name }}</p>
              <button class="document-content__button" @click="resetFile(index)">X</button>
            </div>
          </div>
        </template>
      </DragAndDrop>
    </div>
    <i class="informative-text"
      >Please read our service guidelines. By accepting Insurance you agree to be bound by these
      <a
        href="https://storage.googleapis.com/prod-cargocare-certificade/terms-conditions/Terms%20and%20conditions%20Insurance%20Tool.pdf"
        target="_blank"
      >
        terms and conditions.
      </a>
      All items on this list, or not recognized, can be insured with previous authorization from
      underwritters. Please contact us at insurance@goglt.com</i
    >
    <div class="button-container">
      <button class="button-container__button" @click="certificateInsurance()">Continue</button>
    </div>
  </div>
</template>

<script>
import DragAndDrop from "@/components/DragAndDropFiles.vue";

export default {
  name: "InsuranceAcceptance",
  props: {
    selectedInsurance: Object,
  },
  components: {
    DragAndDrop,
  },
  data() {
    return {
      files: [],
    };
  },
  methods: {
    resetFile(index) {
      if (this.files && index >= 0 && index < this.files.length) {
        this.files.splice(index, 1);
      }
    },
    async certificateInsurance() {
      this.$emit("handlingLoading", true);
      if (this.files.length > 0) {
        for (let i = 0; i < this.files.length; i += 1) {
          // eslint-disable-next-line no-await-in-loop
          const bucketInfo = await this.generateFileUrl(this.files[i].name);
          // eslint-disable-next-line no-await-in-loop
          await this.uploadFileToBucket(bucketInfo.url, this.files[i]);
        }
      }
      this.$emit("certificateInsurance");
    },
    async generateFileUrl(fileName) {
      const response = await this.$store.dispatch("insurance/generateUploadSignedUrl", {
        id: this.$route.params.id,
        fileName,
      });
      return response;
    },
    async uploadFileToBucket(url, file) {
      const body = {
        file: file.raw
      };
      this.$store.dispatch("insurance/updateFileToBucket", { url, formData: body });
    },
  },
};
</script>

<style lang="scss" scoped>
.insurance-acceptance {
  font-family: $font-family-portal;
  color: $color-primary-company;
}
.insurance-acceptance-list {
  margin: 0px;
  padding: 0px;
  list-style: none;
  &__item {
    display: flex;
    flex-direction: column;
    margin: 5px 0px;
    color: $color-primary-company;
  }
}
.documents-container {
  margin: 10px auto;
}
.button-container {
  display: flex;
  justify-content: center;
  &__button {
    width: 280px;
    margin: 20px 0px 10px 0px;
    border: none;
    color: $color-white;
    background: $color-primary-trigger-button;
    border-radius: 15px;
    height: 35px;
    @include font-small-button-text;
    &:hover {
      background-color: $color-primary-trigger-button-hover;
    }
    @media (max-width: 800px) {
      width: 100%;
    }
  }
}
.document-content-list {
  display: flex;
  width: 100%;
}
.document-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  i {
    color: red;
    margin-right: 5px;
  }
  p {
    margin: 0px;
    width: 100%;
  }
  &__button {
    background: none;
    border: none;
  }
}
.drag-and-drop {
  margin-top: 10px;
}
.informative-text {
  font-size: 0.9rem;
  a {
    text-decoration: none;
    color: $color-primary-company;
    text-decoration: underline;
  }
}
</style>
