<template>
  <div class="insurance-certificate-container">
    <div class="insurance-certificate" v-if="selectedInsurance">
      <div class="go-back">
        <button class="go-back__button" @click="changeView('InsuranceSelection')">
          <i class="ion ion-chevron-back-outline"></i>
          <span>Back</span>
        </button>
      </div>
      <div class="insurance-title-container">
        <h3>Generate certificate</h3>
      </div>
      <div class="insurance-form-container">
        <section class="checkboxes-content">
          <InsuranceRadio @updateCollectionInIndexedDB="updateCollectionInIndexedDB" class="mb-2" />
        </section>
        <section class="form-content">
          <InsuranceCertificateForm
            :selectedInsurance="selectedInsurance"
            @updateCollectionInIndexedDB="updateCollectionInIndexedDB"
            ref="insurance-form"
          />
        </section>
      </div>
      <div class="button-container">
        <button class="button-container__button" @click="certificateInsuranceButtonHandler()">
          Continue
        </button>
      </div>
      <CustomModal
        :centered="true"
        :no-close-on-backdrop="true"
        size="lg"
        modalTitle="Insurance Acceptance"
        v-model="insuranceAcceptanceModal"
        :closeOnBackDrop="false"
        :closeOnEsc="false"
        :showOverlay="certifyLoading"
      >
        <template #modal-content>
          <InsuranceAcceptance
            @handlingLoading="handlingLoading"
            @certificateInsurance="certificateInsurance"
            :selectedInsurance="selectedInsurance"
          />
        </template>
      </CustomModal>
    </div>
    <InsuranceCertificateSkeleton v-else />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CustomModal from "@/components/CustomModal.vue";
import InsuranceRadio from "./InsuranceRadio/Index.vue";
import InsuranceCertificateForm from "./InsuranceCertificateForm.vue";
import InsuranceAcceptance from "./components/InsuranceAcceptance.vue";
import DexieDb from "../dbInsurance";
import SchemaCertificateInsurance from "../../../schemas/CertificateInsuranceSchema";
import InsuranceCertificateSkeleton from "./components/InsuranceCertificateSkeleton.vue";

export default {
  name: "InsuranceCertificate",
  components: {
    InsuranceRadio,
    InsuranceCertificateForm,
    CustomModal,
    InsuranceAcceptance,
    InsuranceCertificateSkeleton
  },
  data() {
    return {
      insuranceAcceptanceModal: false,
      selectedInsurance: null,
      loading: false,
      certifyLoading: false,
    };
  },
  async created() {
    this.loading = true;
    this.selectedInsurance = await this.getInsuranceDetailById();
    await this.checkInsuranceIdInIndexedDB(this.insuranceCertificate);
    this.loading = false;
  },
  computed: {
    ...mapGetters({ insuranceCertificate: "insurance/getGenerateCertificateData" }),
  },
  methods: {
    changeView(view) {
      this.$router.push({ name: view, params: { id: this.$route.params.id } });
    },
    async getInsuranceDetailById() {
      const insurance = await this.$store.dispatch(
        "insurance/getInsuranceById",
        this.$route.params.id
      );
      return insurance;
    },
    async certificateInsuranceButtonHandler() {
      this.$store.commit("insurance/setTriggerCertificateValidations");
      const insuranceData = this.$store.getters["insurance/getGenerateCertificateData"];
      const schemaValidation = await this.validateSchema(insuranceData);
      if (!schemaValidation) {
        return;
      }
      this.handlerInsuranceAcceptanceModal(true);
    },
    handlerInsuranceAcceptanceModal(data) {
      this.insuranceAcceptanceModal = data;
    },
    async certificateInsurance() {
      this.certifyLoading = true;
      const insuranceData = this.$store.getters["insurance/getGenerateCertificateData"];
      const payload = {
        id: this.$route.params.id,
        body: insuranceData,
      };
      await this.$store.dispatch("insurance/certifyInsurance", payload);
      this.swal({
        title: "Success",
        text: "Insurance booked with success",
        icon: "success",
      });
      this.deleteIndexedDBInfo();
      this.$store.commit("insurance/setSelectedInsurance", null);
      this.$router.push({ name: "InsuranceDetails", params: { id: this.$route.params.id } });
      this.certifyLoading = false;
    },
    async validateSchema(insuranceData) {
      let schemaValidation = true;
      try {
        await SchemaCertificateInsurance.validate(insuranceData, {
          abortEaryle: true,
        });
      } catch (error) {
        this.swal({
          title: "Missing fields",
          text: error.message,
          icon: "warning",
        });
        schemaValidation = false;
      }
      return schemaValidation;
    },
    // indexDB - persistence form data
    async checkInsuranceIdInIndexedDB(data) {
      const insuranceCollectionData = await DexieDb.getFromDb({
        dbName: "insuranceCertify",
        collection: "certifyInsuranceData",
        id: this.$route.params.id,
      });
      if (!insuranceCollectionData) {
        DexieDb.addToDb({
          collection: "certifyInsuranceData",
          data: {
            insuranceId: this.$route.params.id,
            items: { ...data },
          },
        });
        return;
      }
      this.$store.commit("insurance/setGenerateCertificateData", insuranceCollectionData.items);
    },
    updateCollectionInIndexedDB() {
      DexieDb.updateDb({
        collection: "certifyInsuranceData",
        data: {
          insuranceId: this.$route.params.id,
          items: { ...this.insuranceCertificate },
        },
      });
    },
    deleteIndexedDBInfo() {
      DexieDb.deleteFromDb({
        collection: "certifyInsuranceData",
        id: this.$route.params.id,
      });
    },
    handlingLoading(data) {
      this.certifyLoading = data;
    }
  },
};
</script>

<style lang="scss" scoped>
.go-back {
  &__button {
    background: transparent;
    padding: 0;
    display: flex;
    font-family: $font-family-portal;
    color: $color-primary-trigger-button;
    border: none;
  }
}
.insurance {
  &-title-container {
    color: $color-primary-company;
    margin: 15px 0px;
    h3 {
      display: flex;
      margin: 0px;
      font-size: 1rem;
      font-weight: bold;
    }
  }
}
.button-container {
  margin: 20px auto;
  width: 350px;
  @media (max-width: 810px) {
    width: 100%;
  }
  &__button {
    @include primary-button;
  }
}
</style>
