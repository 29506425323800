<template>
  <div class="insurance-certificate-radio-buttons">
    <label for="radio-buttons" class="label">Packing<span class="required">*</span></label>
    <div class="radio-buttons-list">
      <div v-for="(item, index) in values" :key="index">
        <input
          type="radio"
          :id="item.label"
          :value="item.label"
          :disabled="item.disabled"
          name="packing"
          v-model="packingOption"
        />
        <label :class="item.disabled ? 'disabled-text' : ''" :for="item.label">{{ item.label }}</label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PackingOptions",
  props: {
    value: String,
  },
  data() {
    return {
      packingOption: this.value || null,
      values: [
        { label: "Professionally packed", disabled: false },
        { label: "Unpacked", disabled: true },
        { label: "Manufacturer packed", disabled: false },
        { label: "Not known", disabled: false },
      ],
    };
  },
  watch: {
    packingOption() {
      // this.$emit("input", this.packingOption);
      this.$store.commit("insurance/updateGenerateCertificateProperty", {
        key: "packing",
        value: this.packingOption,
      });
      this.$emit("updateIndexedDB");
    },
    value: {
      immediate: true,
      deep: true,
      handler() {
        if (this.value) {
          this.packingOption = this.value;
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.insurance-certificate-radio-buttons {
  text-align: start;
}
.radio-buttons-list {
  flex-direction: column;
  label {
    margin: 0px;
    margin-left: 5px;
  }
  div {
    margin: 5px 0px;
  }
}
.radio-buttons-list {
  display: flex;
  margin-left: 8px;
}
::v-deep {
  & .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: none !important;
  }

  & .custom-control-input:checked ~ .custom-control-label::before {
    background-color: $color-primary-trigger-button;
    border-color: $color-border-container;
    padding: 9px;
  }

  & .custom-control-label::before {
    border: 1px solid $color-border-container;
  }

  & .custom-radio .custom-control-label::before {
    padding: 9px;
  }

  & .custom-control-label::after {
    width: 1.3rem;
    height: 1.3rem;
    top: 0.25rem;
    left: -1.48rem;
    padding: 9px;
    border-radius: 50%;
  }
}
.disabled-text {
  color: #aeaeae;
}
.required {
  color: red;
}
</style>
