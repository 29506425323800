<template>
  <div class="drag-drop-files">
    <div class="desktop-view" :key="remountCategories">
      <template>
        <slot name="files-list-section">
          <div class="documents-uploaded" v-for="(file, index) in files" :key="index">
            <div class="documents-uploaded__name">
              {{ file.name }}
            </div>
            <div class="documents-uploaded__input">
              <div
                class="input-documents"
                :class="emptyCategories[index] === true ? 'missing-field' : ''"
              >
                <Dropdown
                  v-model="documentsCategory[index]"
                  class="input-information"
                  :class="emptyCategories[index] === true ? 'missing-field' : ''"
                  :options="selectDocuments"
                  optionLabel="text"
                  optionValue="text"
                  :placeholder="$t('claimOrDispute.category')"
                  @change="categorySelected($event.value, index)"
                />
              </div>
              <i class="el-icon-close" @click="handleRemove(file, index)"></i>
            </div>
          </div>
        </slot>
      </template>
      <div class="file-container">
        <el-upload
          class="upload-demo"
          drag
          action="#"
          :auto-upload="false"
          :on-change="handlePreview"
          :on-remove="handleRemove"
          :file-list="files"
          :limit="maxFiles"
          multiple
        >
          <i class="el-icon-document"></i>
          <div class="el-upload__text" v-if="screenWidth >= 800">
            {{ $t("claimOrDispute.dropDocuments") }}
            <em>{{ $t("claimOrDispute.clickUpload") }}</em>
          </div>
          <div class="el-upload__text" v-else>
            <em>{{ $t("claimOrDispute.dropDocumentsMobile") }}</em>
          </div>
        </el-upload>
      </div>
    </div>
  </div>
</template>

<script>
import Dropdown from "primevue/dropdown";
import onReSize from "../Extend/onResize";

export default {
  name: "DragAndDropFiles",
  mixins: [onReSize],
  components: {
    Dropdown,
  },
  props: {
    files: Array,
    selectTypeProblem: Array,
    selectDocuments: Array,
    documentsCategory: Array,
    limitSize: Number,
    maxFiles: Number
  },
  data() {
    return {
      emptyCategories: [],
      remountCategories: 0,
    };
  },
  methods: {
    handleRemove(file, index) {
      this.files.splice(this.files.indexOf(file), 1);
      this.documentsCategory.splice(this.documentsCategory.indexOf(index), 1);
      this.emptyCategories.splice(index, 1);
    },
    handlePreview(file) {
      const sizeInMB = (file.size / (1024 * 1024)).toFixed(2);
      if (sizeInMB < 10.0) {
        this.files.push(file);
      } else {
        this.swal({
          title: "The file should not exceed 10mb in size.",
          icon: "warning",
          dangerMode: false,
        });
      }
    },
    validateFileCategories(files) {
      let validation = true;
      if (files.length < 2) {
        return false;
      }
      files.forEach((file, index) => {
        if (!file.category) {
          this.emptyCategories[index] = true;
          validation = false;
        }
      });
      this.remountCategories += 1;
      return validation;
    },
    categorySelected(category, index) {
      this.files[index].category = category;
      this.emptyCategories[index] = false;
    },
  },
};
</script>

<style lang="scss" scoped>
///****Import styles****\\\\
@import "../assets/scss/components/Dropdown.scss";

.drag-drop-files {
  width: 100%;
}

.desktop-view {
  width: 100%;
}
.mobile-view {
  width: 100%;
}
.documents-name {
  text-align: center;
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
  @include font-standart-text;
  padding: 0 10px;
  &__file {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.file-container-mobile {
  background-color: $color-white;
  border: 1px solid $color-border-container;
  border-radius: 10px;
  height: 30px;
  width: 100%;
  margin: 2rem 0 1rem 0;
}
.input-container {
  @include input-information;
  border: 1px solid $color-border-container;
  height: 40px;
  width: 100%;
  padding: 7px 0px 5px 5px;
  box-shadow: none;
  border-radius: 10px;
  &:hover {
    box-shadow: none;
  }
  &:focus-within {
    box-shadow: none;
  }
}
.input-documents {
  @include input-information;
  border: 1px solid $color-border-container;
  padding: 7px 0px 5px 5px;
  height: 30px;
  width: 85%;
  box-shadow: none;
  border-radius: 10px;
  &:hover {
    box-shadow: none;
  }
  &:focus-within {
    box-shadow: none;
  }
}
.input-information {
  @include input-password;
  width: 100%;
  @include font-small-standart-text;
  color: $color-primary-company;
}

::placeholder {
  color: $color-gray-dark;
}

.documents-uploaded {
  width: 100%;
  margin: 1rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // animation: translateInfo 1s ease;
  transition: background-color 0.5s ease;
  &__input {
    width: 50%;
    display: flex;
    align-items: center;
  }
  &__name {
    width: 50%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

@keyframes translateInfo {
  from {
    opacity: 0;
    transform: translateY(-100%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.missing-field {
  background: $missing-fields;
}

///****File styles****\\\\

::v-deep {
  .el-upload {
    display: flex;
    @media (max-width: 800px) {
      justify-content: flex-end;
    }
  }
  .el-upload__text {
    color: $color-primary-company;
    em {
      color: $color-border-container;
      font-weight: 600;
    }
  }
  .el-upload-dragger {
    border-radius: 10px;
    height: 100px;
    width: 100%;
    border: 1px dashed $color-border-container;
  }
  .el-icon-document:before {
    font-size: 40px;
    color: $color-border-container;
  }
  .el-icon-document {
    margin: 10px 0;
  }
  .el-icon-close:before {
    color: $color-primary-company;
    font-weight: 600;
    margin-left: 10px;
    font-size: 20px;
    cursor: pointer;
  }
  .el-upload-list__item {
    display: none;
  }
  .upload-demo {
    width: 100%;
    text-align: left;
    display: flex;
    flex-direction: column;
  }
  .el-button {
    margin: 3px 5px 2px 0px;
    background-color: $color-primary-company;
    color: $color-white;
    border-radius: 8px;
    border: none;
    padding: 4px;
    @include font-small-button-text;
    font-weight: 400;
    &:hover {
      background-color: $color-primary-company-lighten;
    }
  }
  .el-upload-list {
    margin: 0px 5px 2px 5px;
  }
  .el-upload-list__item:first-child {
    margin-top: 5px;
  }
  .el-upload-list__item-name {
    color: $color-primary-company;
    padding-left: 12px;
  }
}

::v-deep .p-fileupload .p-fileupload-buttonbar .p-button.p-fileupload-choose.p-focus {
  box-shadow: none;
}

::v-deep .p-button.p-button-icon-only {
  padding: 3px;
  width: 1.2rem;
  font-size: 8px;
  background-color: $color-border-container;
  border: none;
}
</style>
