<template>
  <div class="insurance-certificate-radio-buttons">
    <label for="radio-buttons" class="label"
      >Type of Shipments<span class="required">*</span></label
    >
    <div class="radio-buttons-list">
      <div v-for="(item, index) in values" :key="index">
        <input
          type="radio"
          :id="item.label"
          :value="item.label"
          name="shipment"
          v-model="shipmentsOption"
        />
        <label :for="item.label">{{ item.label }}</label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ShipmentsOptions",
  props: {
    value: String,
  },
  data() {
    return {
      shipmentsOption: this.value || null,
      values: [
        { label: "Full Truck/Container load" },
        { label: "Breakbulk" },
        { label: "Less Truck/Container load" },
      ],
    };
  },
  watch: {
    shipmentsOption() {
      // this.$emit("input", this.shipmentsOption);
      this.$store.commit("insurance/updateGenerateCertificateProperty", {
        key: "type_of_shipment",
        value: this.shipmentsOption,
      });
      this.$emit("updateIndexedDB");
    },
    value: {
      immediate: true,
      deep: true,
      handler() {
        if (this.value) {
          this.shipmentsOption = this.value;
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.insurance-certificate-radio-buttons {
  text-align: start;
}
.radio-buttons-list {
  flex-direction: column;
  label {
    margin: 0px;
    margin-left: 5px;
  }
  div {
    margin: 5px 0px;
  }
}
.radio-buttons-list {
  display: flex;
  margin-left: 8px;
}
.required {
  color: red;
}
</style>
