<template>
  <div class="insurance-checkboxes">
    <div class="checkboxes-container">
      <div class="checkboxes-container__left">
        <PackingOptions @updateIndexedDB="updateIndexedDB" :value="packingValue" />
      </div>
      <div class="checkboxes-container__right">
        <ShipmentsOptions @updateIndexedDB="updateIndexedDB" :value="shipmentsValue" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PackingOptions from "./PackingOptions.vue";
import ShipmentsOptions from "./ShipmentsOptions.vue";

export default {
  name: "InsuranceRadio",
  components: {
    PackingOptions,
    ShipmentsOptions,
  },
  data() {
    return {
      packingValue: null,
      shipmentsValue: null,
    };
  },
  computed: {
    ...mapGetters({ insuranceCertificate: "insurance/getGenerateCertificateData" }),
  },
  watch: {
    insuranceCertificate: {
      immediate: true,
      deep: true,
      handler() {
        this.shipmentsValue = this.insuranceCertificate.type_of_shipment;
        this.packingValue = this.insuranceCertificate.packing;
      },
    },
  },
  methods: {
    updateIndexedDB() {
      this.$emit("updateCollectionInIndexedDB");
    },
  },
};
</script>

<style lang="scss" scoped>
.checkboxes-container {
  display: flex;
  justify-content: space-between;
  &__left {
    width: 50%;
  }
  &__right {
    width: 50%;
  }
}
</style>
