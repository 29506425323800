import * as yup from 'yup';

const schema = yup.object().shape({
  packing: yup.string().required("Packing must have an option"),
  type_of_shipment: yup.string().required("Type of shipments must have an option"),
  marks_or_numbers: yup.string(),
  transportation_service: yup.string().required("Transportation service is required"),
  transportation_service_id: yup.string().required("Transportation service ID is required"),
  loss_payable: yup.object().shape({
    name: yup.string().required("Loss payable name is required"),
    address: yup.string().required("Loss payable address is required"),
    location_info: yup.string().required("Loss payable location is required"),
  }),
  reference_number: yup.string(),
  sailing_date: yup.date().required().typeError("Sailing date is required")
});

export default schema;
